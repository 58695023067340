
import * as ActiveStorage from '@rails/activestorage'
ActiveStorage.start()

import '../controllers/'

// ADD HTMX (the chad library)
// import { htmx } from "htmx.org"
// import "htmx-ext-path-params"
// Theres a bug in HTMX that is preventing us from being able to import the JS API at the moment 
// https://github.com/bigskysoftware/htmx/issues/2733
// Once this is fixed well use this, but for the mean time we're using it via the CDN

// Import tailwind
import '../../assets/stylesheets/application.css'
import '../../assets/stylesheets/actiontext.css'
import '../../assets/stylesheets/select2custom.css'

import "trix"
import '@rails/actiontext'
import { HTMLFormAttributes } from 'svelte/elements'

// @ts-ignore
window.addEventListener("trix-file-accept", event => {
  if (event.target instanceof HTMLElement && event.target.parentElement.classList.contains('trix-attachments-enabled')) {
    return
  } else {
    event.preventDefault()
    alert("File attachment not supported!")
  }
})


// @ts-ignore
document.addEventListener("htmx:configRequest", (event: any) => {
  // This allows dynamicaly setting the request path to a value
  // Used in the period selector dropdown
  if ((event.detail.path as string).charAt(0) == ':') {
    const paramName = event.detail.path.slice(1)
    const newPath = event.detail.parameters[paramName]
    delete event.detail.parameters[paramName]
    event.detail.path = newPath
  }

  // Set the CSRF token in the request headers
  const csrfNode = document.querySelector("meta[name=csrf-token]")
  if (csrfNode instanceof HTMLMetaElement) {
    event.detail.headers['X-CSRF-Token'] = csrfNode.content
  }

  // Prefer HTML response type: text/html
  event.detail.headers['Accept'] = "text/html, */*;q=0.8"
})


// If the csrf token changes , swap the csrf token
// HTMX doesn't provide this functionality out of the box by the looks
//@ts-ignore
document.addEventListener("htmx:afterSwap", (event: CustomEvent) => {
  const parser = new DOMParser()
  const shadowDoc = parser.parseFromString(event.detail.xhr.response, "text/html")
  const currentMetaNode = document.querySelector("meta[name='csrf-token']")
  const newMetaNode = shadowDoc.querySelector("meta[name='csrf-token']")
  if (!(newMetaNode instanceof HTMLMetaElement) || !(currentMetaNode instanceof HTMLMetaElement)) { return }
  currentMetaNode.content = newMetaNode.content
})

// Disable form buttons while requesting
// @ts-ignore
document.addEventListener("htmx:beforeRequest", (event: CustomEvent) => {
  const elt = event.detail.elt
  if (!(elt instanceof HTMLFormElement)) {return}
  elt.querySelectorAll("input[type='submit']").forEach((node) => {
    if (!(node instanceof HTMLInputElement)) { return }
    node.disabled = true
    // If the request is canned then htmx:afterRequest is never triggered, so we re-enable the form after some time as a backup
    setTimeout(() => { node.disabled = false}, 1000) 
  })
})

// Restore form inputs after swapped
//@ts-ignore
document.addEventListener("htmx:afterRequest", (event: CustomEvent) => {
  const elt = event.detail.elt
  if (!(elt instanceof HTMLFormElement)) { return }
  elt.querySelectorAll("input[type='submit']").forEach(node => {
    if (!(node instanceof HTMLInputElement)) { return }
    node.disabled = false
  })
})

declare global {
  let htmx: any
}

htmx.config.responseHandling = [
  {code:"204", swap: false},   // 204 - No Content by default does nothing, but is not an error
  {code: "[23]..", swap: true}, // 200 & 300 responses are non-errors and are swapped
  {code: "422", swap: true, error: true}, // When a validation error is returned do a swap
  {code:"[45]..", swap: false, error: true}, // 400 & 500 responses are not swapped and are errors
]